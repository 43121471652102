/* Auth */
.auth {
    height: 100%;

    .login-half-bg {
        background: url("../../../images/auth/login-bg.jpg");
        background-size: cover;
    }

    .register-half-bg {
        background: url("../../../images/auth/register-bg.jpg");
        background-size: cover;
    }

    &.lock-full-bg {
        background: url("../../../images/auth/lockscreen-bg.jpg");
        background-size: cover;
    }

    .lock-profile-img {
        width: 90px;
        height: 90px;
        border-radius: 100%;
    }

    .auth-form-light {
        background: $white;

        select {
            color: $input-placeholder-color;
        }

        .input-group {
            .form-control {

                &:focus,
                &:active {
                    border-color: $border-color;
                }
            }
        }
    }

    .auth-form-dark {
        background: $card-bg;

        select {
            color: $input-placeholder-color;
        }

        .input-group {
            .form-control {

                &:focus,
                &:active {
                    border-color: $border-color;
                }
            }
        }
    }

    .auth-form-transparent {
        background: transparent;

        .form-control,
        .input-group-text {
            border-color: theme-color(secondary);

            &:focus,
            &:active {
                border-color: theme-color(secondary);
            }
        }

        select {
            outline-color: theme-color(secondary);
        }
    }

    &.auth-img-bg {
        padding: 0;

        .auth-form-transparent {
            @media (min-width:768px) {
                width: 55%;
                margin: auto;
            }
        }
    }

    .brand-logo {
        margin-bottom: 2rem;

        img {
            width: 150px;
        }
    }

    form {
        .form-group {
            margin-bottom: 1.5rem;

            label {
                font-size: .8125rem;
            }

            .form-control {
                background: transparent;
                border-radius: 0;
                font-size: .9375rem;
            }
        }

        .auth-form-btn {
            padding: 1rem 2rem;
            line-height: 1.5;
        }

        .auth-link {
            font-size: $default-font-size;

            &:hover {
                color: initial;
            }
        }
    }
}
.loading_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  p {
    color: black;
    margin-top: 10px !important;
    font-size: 16px;
  }
}
.manual_payment_setting {
  .body_container {
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;

    .paymnet_container {
      .top_heading {
        margin-top: 20px;

        p {
          font-size: 20px !important;
          font-weight: 500;
          text-align: center;
        }
      }
      .qr_code {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 70%;
        }
      }
      .upi_id {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          text-align: center;
          margin-bottom: 15px !important;
          font-size: 16px !important;
          margin-top: 10px !important;

          span {
            color: #007bff !important;
          }
        }
        .copy_icon {
          margin-left: 4px !important;
          position: relative;
          width: 30px;

          svg {
            font-size: 20px !important;
            margin-top: -5px;
            cursor: pointer;
          }
          .copy_icon {
            color: gray;
            &:hover {
              color: black;
            }
          }
          .check_icon {
            color: green;
            margin-left: 5px;
          }
        }
      }
      .para_contain {
        p {
          text-align: center;
          font-size: 14px !important;
          margin: 0px !important;
          color: gray;
        }
      }
    }

    .paymnet_detail_form_container {
      border: 1px solid lightgray;
      border-radius: 10px;
      padding: 20px 10px;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 50px;
      .file_input {
        margin-top: 20px;
      }

      .number_input_container {
        width: 100%;

        .number_input {
          width: 100%;
          padding: 10px;
          padding-left: 15px;
          border-radius: 8px;
          border: 1px solid lightgray !important;
          &:focus {
            border: 1px solid black !important;
          }
        }
        label {
          color: rgb(72, 72, 72) !important;
          margin-left: 2px;
          margin-bottom: 2px;
        }
      }
      .error_para {
        font-size: 12px !important;
        color: red;
        opacity: 0;
      }

      button {
        min-width: 100%;
        height: 40px;
        width: 126px;
        border: none;
        border-radius: 5px;
        border: 1px solid black !important;
        background-color: white !important;
        color: black !important;
        margin-top: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: black !important;
          color: white !important;
        }
      }
    }
  }
}
.error_para_opacity {
  opacity: 1 !important;
}

.successfully_message {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid green;
  margin-top: 30px;
  p {
    color: green !important;
    margin: 0px !important;
  }
}

.display_none {
  display: none !important;
}

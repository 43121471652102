.game_message_main_container {
  background-color: #dc3545 !important;
  margin: 10px 20px !important;
  border-radius: 5px !important;
  padding: 10px 10px !important;
  // height: 60px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #bc2a39 !important;
  }

  p {
    color: white;
    font-weight: 500;
    // padding: 5px;
    font-size: 14px !important;
    margin: 0px !important;
  }

  .loading {
    text-align: center;
  }
}
